.page-actions {
  position: absolute;
  bottom: 6.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.page-actions > *:not(:last-child) {
  margin-right: 5.2rem;
}
