.alphaKeyboard {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.alphaKeyboard .alphaKeyboard--line {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.alphaKeyboard button {
  width: 10rem;
  height: 9rem;
  margin-right: 0.2rem;
  font-weight: 600;
  font-size: 2.8rem;
  line-height: 4.2rem;
}

.alphaKeyboard button svg {
  margin: auto;
}

.alphaKeyboard .alphaKeyboard--line > button:last-child {
  margin-right: 0;
}

.alphaKeyboard .alphaKeyboard-space {
  width: 37.9rem;
  height: 7.479rem;
  font-weight: 600;
  font-size: 2.8rem;
}

.alphaKeyboard--line1 {
  display: flex;
  justify-content: space-between;
}

.alphaKeyboard .alphaKeyboard-space {
  margin-left: calc(50% - 19rem);
}

.alphaKeyboard .alphaKeyboard-enter {
  width: 18.4rem;
  height: 9rem;
  font-weight: 600;
  font-size: 2.8rem;
  color: black;
}

.alphaKeyboard .alphaKeyboard-backSpace {
  width: 14rem !important;
}

.alphaKeyboard .shift-key svg path {
  stroke: white;
}

.alphaKeyboard .shift-key.shift-key-active svg path {
  fill: white;
}

.alphaKeyboard button *::first-letter {
  text-transform: none !important;
}
