.module-master__title {
  text-align: center;
}

.module-master .module-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60rem;
  height: 40rem;

  background: rgba(241, 109, 148, 0.2);
  border-radius: 4rem;
  padding-top: 3.6rem;
}

.module-master .module-card:nth-child(2) {
  background: rgba(40, 172, 226, 0.2);
}

.module-master .module-card:nth-child(3) {
  background: rgba(208, 175, 232, 0.5);
}

.module-master .module-card:nth-child(4) {
  background: rgba(249, 157, 43, 0.3);
}

.module-master .module-card img {
  max-height: 100%;
  max-width: 100%;
}

.module-master .page-title {
  margin-top: 6.8rem;
  margin-bottom: 4.6rem;
}

.module-master .module-master-wrapper {
  display: grid;
  grid-template-columns: 60rem 60rem;
  grid-template-rows: 40rem 40rem;
  gap: 3.6rem;
}
