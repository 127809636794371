.pb-checkbox-label {
}

.pb-checkbox-wrapper {
  display: inline-flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
}

.pb-checkbox-input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  color: var(--sync-primary-color);
  width: 4.5rem;
  height: 4.5rem;
  border: 1px solid black;
  border-radius: 1rem;
  display: grid;
  place-content: center;
  cursor: pointer;
}

.pb-checkbox-input[type='checkbox']::before {
  content: '';
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 1rem;
  transform: scale(0);
  transition: 50ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: var(--sync-primary-color);
}

.pb-checkbox-input[type='checkbox']:checked::before {
  transform: scale(1);
}
