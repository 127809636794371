.theme-page__title {
  margin-top: 7.6rem;
  margin-bottom: 1.5rem;
}

.theme-page__theme-photo-sheet--wrapper {
  display: flex;
  align-items: center;
  margin-right: 6rem;
  height: 64rem;
  width: 64rem;
}

.theme-page__theme-photo-sheet--wrapper .theme-page__theme-photo-sheet {
  max-height: 64rem;
  max-width: 64rem;
  margin: auto;
}

.theme-page__selection-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14.5rem;
}

.theme-page .theme-page__theme-carousel--wrapper {
  width: 72.8rem;
}

.theme-page .theme-page__theme-carousel {
  margin: auto;
}

.theme-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.theme-item {
  width: 16rem;
  height: 16rem;
  border-radius: 2rem;
  position: relative;
  border: 0.2rem solid var(--sync-button-border-color);
}

.theme-item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 2rem;
}

.theme-item.active {
  border-radius: 2.3rem;
  border: 0.4rem dashed var(--sync-text-color);
  padding: 0.8rem;
}

.theme-page__theme-category-carousel--wrapper {
  height: 5rem;
  margin-bottom: 1.5rem;
}

.theme-page__theme-category-carousel--wrapper .carousel {
  margin: auto;
  width: 120rem;
  height: 5rem;
}

/* .theme-page__theme-category-carousel--wrapper .carousel,
.theme-page__theme-category-carousel--wrapper .carousel-item,
.theme-page__theme-category-carousel--wrapper .carousel-main-view {
  height: inherit;
  width: 120rem;
} */

.theme-page__theme-category-carousel--wrapper button {
  width: 22rem;
  white-space: nowrap;
  height: 5rem;
  position: relative;
  margin: 0 1rem;
  overflow: hidden;
  padding: 0 1rem;
  font-weight: normal;
  border-width: 0.2rem;
  border-color: transparent !important;
}

.theme-page__theme-category-carousel--wrapper button * {
  font-size: 2.8rem;
  line-height: unset !important;
}

.theme-page__theme-category-carousel--wrapper button.category-button-active {
  border-color: var(--sync-button-border-color) !important;
}

.theme-page__theme-category-carousel--wrapper
  > button.category-button-active::after {
  display: block;
}

/* Vertical Screen */
.app-vertical-screen .theme-page {
}

.app-vertical-screen .theme-page__selection-section {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 0;
}

.app-vertical-screen .theme-page__page-actions--vertical {
  position: relative;
  bottom: 0;
  margin: 10rem 0 5rem;
}

.app-vertical-screen .theme-item.active {
  border-width: 0.2rem;
}

.app-vertical-screen .theme-page__theme-photo-sheet--wrapper {
  margin: 0;
}

/* Vertical Screen category-carousel */
.app-vertical-screen .theme-page__theme-category-carousel--wrapper {
  margin-bottom: 5rem;
}

.app-vertical-screen .theme-page__theme-category-carousel--wrapper .carousel {
  width: 80rem;
}

.app-vertical-screen .theme-page__theme-category-carousel--wrapper button {
  width: 14rem;
}

.app-vertical-screen
  .theme-page__theme-category-carousel--wrapper
  .pb-typography {
  font-size: 2.2rem;
}

/* Vertical Screen theme-carousel */

/* Vertical Screen theme-carousel */
.app-vertical-screen .theme-page__theme-carousel--wrapper {
  width: 80rem;
  margin-bottom: 6.5rem;
}

.app-vertical-screen .theme-page__theme-carousel {
  width: inherit;
  height: 12rem;
}

.app-vertical-screen .theme-page__theme-carousel--wrapper .carousel-indicators {
  margin-top: 1.5rem;
}

.app-vertical-screen
  .theme-page__theme-carousel--wrapper
  .carousel-indicators
  button {
  width: 2rem;
  height: 2rem;
}

/* Vertical Screen category-carousel */

.app-vertical-screen .theme-group {
  grid-template-columns: repeat(auto-fit, 12rem);
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.app-vertical-screen .theme-item {
  width: 12rem;
  height: 12rem;
}
