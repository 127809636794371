.pb-keyboardDrawer-container .pb-keyboardDrawer-backdrop {
  display: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: var(--z-index-keyboard);
  background-color: #ffffff50;
}

.pb-keyboardDrawer-container.pb-keyboardDrawer-container-open
  .pb-keyboardDrawer-backdrop {
  display: block;
}

.pb-keyboardDrawer-container .pb-keyboardDrawer {
  position: fixed;
  width: 100vw;
  left: 0;
  bottom: -100%;
  background-color: rgba(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.25s ease-in-out;
  padding: 3rem 0 2rem;
  z-index: calc(var(--z-index-keyboard) + 1);
  background-image: url('assets/v2/images/blue_cartoon_background_1.png');
  background-size: 40rem;
}

.pb-keyboardDrawer-container.pb-keyboardDrawer-container-open
  .pb-keyboardDrawer {
  bottom: 0;
}

.pb-keyboardDrawer .pb-keyboardDrawer-input {
  background-color: var(--sync-secondary-color);
  padding-left: 10rem;
  padding-right: 10rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 3rem;
  font-size: 4.2rem;
  color: white;
}

.pb-keyboardDrawer .pb-keyboardDrawer-input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: white;
}

.pb-keyboardDrawer .pb-keyboardDrawer-input::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: white;
}

.pb-keyboardDrawer .pb-keyboardDrawer-input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: white;
}

.pb-keyboardDrawer .pb-keyboardDrawer-input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: white;
}

.pb-keyboardDrawer .pb-keyboardDrawer-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: white;
}

.voucher-code-error-text {
  margin-top: -1.5rem;
  margin-bottom: 2rem;
  color: var(--danger-color);
}

.app-vertical-screen .alphaKeyboard button:not(.alphaKeyboard-enter) {
  width: 8rem;
  height: 8rem;
}
.app-vertical-screen .pb-keyboardDrawer {
  padding: 8rem 0;
}
.app-vertical-screen .pb-keyboardDrawer {
  bottom: calc(50% - 33.4rem);
  opacity: 0;
}
.app-vertical-screen
  .pb-keyboardDrawer-container.pb-keyboardDrawer-container-open
  .pb-keyboardDrawer {
  bottom: calc(50% - 33.4rem);
  opacity: 1;
}
