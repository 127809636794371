@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Inter';
    font-weight: 100;
    src: url('assets/fonts/Inter-Thin.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 200;
    src: url('assets/fonts/Inter-ExtraLight.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 300;
    src: url('assets/fonts/Inter-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url('assets/fonts/Inter-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url('assets/fonts/Inter-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url('assets/fonts/Inter-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url('assets/fonts/Inter-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 800;
    src: url('assets/fonts/Inter-ExtraBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 900;
    src: url('assets/fonts/Inter-Black.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Coiny';
    font-weight: 400;
    src: url('assets/fonts/coiny-regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Nunito';
    font-weight: 400;
    src: url('assets/fonts/Nunito-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Nunito';
    font-weight: 700;
    src: url('assets/fonts/Nunito-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Nunito';
    font-weight: 900;
    src: url('assets/fonts/Nunito-Black.ttf') format('truetype');
  }
}

@layer components {
  .start-page-title,
  .print-title {
    @apply font-Coiny text-[9rem] inline-block !important;
    @apply uppercase relative inline-block;
    color: var(--sync-title-color) !important;
  }

  .page-title {
    @apply font-Coiny text-[5.5rem] inline-block !important;
    @apply uppercase relative inline-block;
    color: var(--sync-title-color) !important;
  }

  .page-title-margin {
    margin-top: 8rem;
    margin-bottom: 5rem;
    text-align: center;
  }

  .start-page-title.text-stroke-swap,
  .print-title.text-stroke-swap {
    @apply animate-tilt-n-move-shaking;
  }

  .start-page-title::before,
  .print-title::before {
    content: attr(data-text);
    position: absolute;
    z-index: -1;
    -webkit-text-stroke: 0.2em var(--sync-border-title-1-color);
    left: 0;
  }

  .start-page-title::after,
  .print-title::after {
    content: attr(data-text);
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    -webkit-text-stroke: 0.4em var(--sync-border-title-2-color);
  }

  .page-title::before {
    content: attr(data-text);
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    -webkit-text-stroke: 0.25em var(--sync-border-title-1-color);
  }

  .page-title::after {
    content: attr(data-text);
    position: absolute;
    z-index: -2;
    left: 0;
    top: 0;
    -webkit-text-stroke: 0.5em var(--sync-border-title-2-color);
  }

  .page-title-inactive {
    @apply font-Coiny text-[5.5rem] inline-block !important;
    @apply uppercase relative inline-block;
    color: white !important;
  }

  .page-title-inactive::before {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-text-stroke: 0.25em var(--sync-primary-color);
  }

  .dashed-border-select-frame {
    background-image: var(--frame-dashed-border);
    border-radius: 4rem;
    padding: 1rem;
  }

  .dashed-border {
    background-image: var(--frame-dashed-border);
    border-radius: 4rem;
    padding: 1rem;
  }
}

html,
body {
  overscroll-behavior-x: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}

* {
  outline: none;
}

:root {
  --danger-color: #f87171;
  --frame-dashed-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='40' ry='40' stroke='%23fff' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  --v2-fun-white-color: #def4f6;
  --momo-color: #b11f72;
  --divide-border-color: #878787;

  --sync-primary-color: #ee5e77;
  --sync-secondary-color: #3a81c3;
  --sync-text-color: #def4f6;
  --sync-button-text-color: white;
  --sync-button-border-color: white;
  --sync-input-background-color: #f3f3c5;
  --sync-title-color: white;
  --sync-border-title-1-color: #3a81c3;
  --sync-border-title-2-color: #ffc840;

  --background-image-url: url('assets/v2/images/pink_background.png');
  --sync-live-view-dashed-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='40' ry='40' stroke='%23fff' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  --sync-left-frame-dashed-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='40' ry='40' stroke='%23fff' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  --sync-right-frame-dashed-border: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='40' ry='40' stroke='%23fff' stroke-width='6' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  --sync-left-frame-background-color: #def4f6;
  --sync-right-frame-background-color: white;

  --z-index-retake-result: 1000;
  --z-index-dropdown: 1200;
  --z-index-backdrop: 1300;
  --z-index-button-on-backdrop: 1300;
  --z-index-freeze-webcam-loading: 1200;
  --z-index-loading: 1200;
  --z-index-popup: 1400;
  --z-index-popover: 1400;
  --z-index-keyboard: 1300;

  --font-size-small: 2.4rem;
  --font-size-xs: 1.5rem;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
  pointer-events: none;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  white-space: normal;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.no-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.transition-img {
  transition: all 0.5s;
}

.blink-opacity {
  opacity: 1;
  -webkit-animation: blink-animation 1s linear infinite;
  animation: blink-animation 1s linear infinite;
}

.blink-medium-opacity {
  opacity: 1;
  -webkit-animation: blink-medium-animation 1s linear infinite;
  animation: blink-medium-animation 1s linear infinite;
}

.blink-take-photo {
  opacity: 1;
  -webkit-animation: blink-take-photo 0.1s linear infinite;
  animation: blink-take-photo 0.1s linear infinite;
}

@-webkit-keyframes blink-medium-animation {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink-medium-animation {
  0% {
    opacity: 0.8;
  }

  50% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink-take-photo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink-take-photo {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
