.print-title-container {
  display: flex;
  flex-direction: column;
}

.print-title-wrapper {
  margin-top: auto;
  margin-bottom: auto;
}

.print-title-wrapper.is-has-mascot {
  margin-top: 16rem !important;
  margin-bottom: 8rem;
}

.print-title-wrapper > * {
  line-height: 10rem;
}

.print-image-wrapper {
  width: 150rem;
  height: 60rem;
}

.print-image-wrapper img {
  max-width: 100%;
  margin: auto;
  width: 100%;
  height: 100%;
}

.user-manual-video-wrapper img {
  max-width: 100%;
}

/* Vertical Screen */
.app-vertical-screen .print-title-container {
  justify-content: center;
  align-items: center;
}

.app-vertical-screen .print-image-wrapper {
  height: unset;
  max-width: 95%;
}

.app-vertical-screen .print-title-wrapper {
  max-width: 80%;
}

.app-vertical-screen .print-title-wrapper.is-has-mascot {
  margin-bottom: 16rem;
  margin-top: 0rem !important;
}

.app-vertical-screen .print-image-wrapper {
  height: 70rem;
  width: unset;
}
