.payment-title {
  margin-top: 6.8rem;
  margin-bottom: 11.2rem;
}

.printing-payment__field-label {
  width: 30rem;
  margin-right: 4rem;
}

.payment-right-group {
  width: 68rem;
}

.payment-right-group input {
  outline: none;
}

.input-need-pay,
.input-voucher {
  width: 66rem;
  height: 10rem;
  color: var(--sync-secondary-color);
  font-size: 5.1rem;
  background-color: var(--sync-input-background-color);
}

.input-voucher {
  font-size: 4.2rem;
}

.input-voucher::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: var(--sync-secondary-color);
}

.input-voucher::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: var(--sync-secondary-color);
}

.input-voucher:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: var(--sync-secondary-color);
}

.input-voucher::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: var(--sync-secondary-color);
}

.input-voucher::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 400;
  color: var(--sync-secondary-color);
}

.printing-payment__form {
  margin-top: 24rem;
}

.printing-payment__form-row {
  display: flex;
  align-items: center;
}

.printing-payment__form-row:not(:last-child) {
  margin-bottom: 6rem;
}

.input-deposited {
  width: 66rem;
  height: 10rem;
  font-size: 5.1rem;
  border-color: var(--sync-button-border-color);
  background-color: var(--sync-primary-color);
  color: var(--sync-button-text-color);
}

.button-group-more-pic {
  white-space: nowrap;
}

.button-more-pic:not(:last-child) {
  margin-right: 4rem;
}

.change-money-warning {
  margin-top: -3.2rem;
}

.change-money-warning-text {
  font-style: italic;
  font-weight: 700;
  margin-top: -1rem;
  margin-left: 1rem;
}

.printing-payment__field-label .pb-typography {
  color: var(--sync-text-color);
}

/* Vertical Screen */
.printing-payment__page-actions--vertical {
  position: relative;
  bottom: 0;
  margin: 10rem 0 20rem;
}

.app-vertical-screen .printing-payment__form {
  margin-bottom: 0;
  padding-left: 3rem;
}

.app-vertical-screen .printing-payment__field-label .pb-typography {
  font-weight: 800;
  font-size: 3.8rem;
}
