.start-page-title-container {
  display: flex;
  flex-direction: column;
}

.start-page-title-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 100%;
}

.start-page-title-wrapper.is-has-mascot {
  margin-top: 25rem;
}

.start-page-image-wrapper {
  width: 153rem;
  height: 65.1rem;
  margin: 8rem auto;
}

.start-page-image-wrapper img {
  max-width: 100%;
  margin: auto;
  width: 100%;
  height: 100%;
}

.user-manual-video-wrapper img {
  max-width: 100%;
}

/* App Vertical Screen */
.app-vertical-screen .start-page-image-wrapper {
  width: 153rem;
  height: unset;
  max-width: 80%;
}

.app-vertical-screen .start-page-title-container {
  justify-content: center;
  align-items: center;
}

.app-vertical-screen .start-page-title-wrapper {
  margin-top: auto;
}
