.layout-title {
  margin-top: 6.8rem;
  margin-bottom: 3.2rem;
}

.select-layout__quantity-group {
  text-align: center;
}

.select-layout__quantity-group > button {
  width: 18rem;
  height: 6rem;
  margin-bottom: 8rem;
  position: relative;
  color: var(--sync-text-color);
}

.select-layout__quantity-group > button::after {
  content: attr(data-label);
  display: none;
  position: absolute;
  color: var(--sync-text-color);
  font-family: Nunito;
  font-size: 2.4rem;
  font-style: italic;
  font-weight: 700;
  line-height: 2.9rem;
  letter-spacing: 0em;
  text-align: center;
  top: 7rem;
  width: 100%;
  left: 0;
}

.select-layout__quantity-group
  > button.select-layout__quantity-button--active::after {
  display: block;
}

.select-layout__quantity-group
  > button:not(.select-layout__quantity-button--active)
  .pb-typography {
  color: var(--sync-text-color);
}

.select-layout__img-examples {
  height: 55rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-layout__img-examples img {
  width: auto;
  max-width: 55rem;
  max-height: 100%;
  padding: 0.8rem;
  background-color: transparent;
  border: 2px solid transparent;
  pointer-events: visible;
}

.select-layout__img-examples img:not(:last-child) {
  margin-right: 4.7rem;
}
