.select-frame__item {
  width: 60rem;
  height: 76.6rem;
  background-image: var(--sync-left-frame-dashed-border);
}

.select-frame__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 3rem;
  background: url('assets/v2/images/blue_cartoon_background.png');
  background-size: 60rem;
  background-color: var(--sync-left-frame-background-color);
  padding: 2rem;
}

.select-frame__item--right {
  margin-left: 8rem;
  background-image: var(--sync-right-frame-dashed-border);
}

.select-frame__item--right .select-frame__btn {
  background: url('assets/v2/images/blue_cartoon_background.png');
  background-size: 60rem;
  background-color: var(--sync-right-frame-background-color);
}

.select-frame__btn img {
  max-height: 100%;
  max-width: 100%;
  min-height: 0;
}

.select-frame__btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* App Vertical Screen */
.app-vertical-screen .select-frame {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.app-vertical-screen .select-frame__item {
  width: 45rem;
  height: 57.45rem;
}
.app-vertical-screen .select-frame__btn-group {
  height: 100%;
}
