.printing-portrait-layout {
  .page-title-margin {
    margin-bottom: 4rem;
  }

  .printing-portrait-layout__payment-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;

    & > * {
      color: var(--sync-text-color);
      font-weight: 600;
    }
  }

  .printing-portrait-layout__wrapper {
    width: 120.889rem;
    height: 68rem;
  }

  .printing-portrait-layout__slider-wrapper {
    width: unset;
    margin-top: 6rem;

    .printing-portrait-layout__layout-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 58rem;

      img {
        margin: 3rem 0;
      }
    }
  }
}
