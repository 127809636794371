.photo-guide-title {
  margin-top: 6.8rem;
  margin-bottom: 3.8rem;
}

.photo-guide-card {
  width: 58.8rem;
  height: 74.8rem;
  border-radius: 5.4rem;
  margin-left: 7.3rem;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.7rem 4rem;
}

.photo-guide-card:not(:last-of-type) {
  margin-left: 0;
}

.photo-guide-card-title {
  margin-bottom: 4.3rem;
}

.photo-guide-card-title svg {
  margin-right: 1rem;
  display: inline-block;
  vertical-align: baseline;
}

.photo-guide-card-title svg path {
}

.photo-guide .group-info-wrapper {
  width: 100%;
}

.photo-guide .group-info-wrapper-title {
  margin-bottom: 2rem;
  color: #000;
}

.photo-guide .group-info-wrapper-value {
  color: #000;
}

.photo-guide .group-info-wrapper-description {
  color: #000;
}

.photo-guide .group-info-wrapper-description:not(:last-of-type) {
  margin-bottom: 2rem;
}

.photo-guide .group-info-wrapper:not(:last-of-type) {
  margin-bottom: 3rem;
}

.photo-guide .group-info-item {
  display: flex;
  justify-content: space-between;
}

.photo-guide .group-info-item:not(:last-of-type) {
  margin-bottom: 2rem;
}

.photo-guide .info-wrapper {
  margin-top: 0.4rem;
}

.photo-guide .info-title {
  margin-bottom: 2rem;
}

.photo-guide .bg-info-title {
  font-weight: 700;
  margin-top: 2.2rem;
  color: white;
  border-radius: 4.2rem;
  padding: 0.8rem 2.1rem 0.8rem 3.2rem;
}

.photo-guide .img-girl-blink {
  margin: 3rem auto;
}

.photo-guide .support-text {
  font-weight: 400;
}

.photo-guide .title-text {
  color: #000;
  font-weight: 700;
}

.photo-guide .normal-text {
  color: #000;
  font-weight: 400;
}
