.pick-photo-title {
  margin-top: 7.6rem;
  margin-bottom: 8rem;
}

.pick-photo__section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pick-photo__image-frame {
  display: flex;
  align-items: center;
  margin-right: 5.4rem;
  height: 64rem;
  width: 64rem;
}

.pick-photo__image-frame .pick-photo__example-img {
  max-height: 64rem;
  max-width: 64rem;
  margin: auto;
}

.pick-photo__img-grid {
  width: 73.4rem;
}

.pick-photo__img-grid .pick-photo__img-row:not(:last-of-type) {
  margin-bottom: 0.3rem;
}

.pick-photo__img-grid .pick-photo__img-pick {
  border-radius: 2.4rem;
  padding: 0.4rem;
  position: relative;
  border: 0.4rem solid transparent;
  vertical-align: middle;
}

.pick-photo__img-grid .pick-photo__img-pick.active {
  border: 0.4rem dashed var(--sync-text-color);
  border-radius: 2.4rem;
}

.pick-photo__img-grid .pick-photo__img-pick.active .pick-photo__img-order-no {
  visibility: visible;
}

.pick-photo__img-grid .pick-photo__img-pick:not(:last-of-type) {
  margin-right: 0.6rem;
}

.pick-photo__img-grid .pick-photo__img-pick > img {
  border-radius: inherit;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
  border: 0.4rem solid white;
}

.pick-photo__img-grid .pick-photo__img-order-no {
  visibility: hidden;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  top: 1.6rem;
  left: 1.6rem;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid var(--sync-primary-color);
}

.pick-photo__img-grid .pick-photo__img-order-no-text {
  color: var(--sync-primary-color);
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.pick-photo__btn-rotate-actions {
  position: absolute;
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
}

.pick-photo__btn-rotate-actions button {
  width: 5rem;
  height: 5rem;
  margin: 0 0.5rem;
  background-color: var(--sync-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  border-radius: 0.8rem;
}

.pick-photo__btn-rotate-actions button img {
  width: 100%;
  height: 100%;
  filter: brightness(0) invert(1);
}

/* Vertical Screen */
.app-vertical-screen .pick-photo {
}

.app-vertical-screen .pick-photo__img-grid {
  width: unset;
  margin-bottom: 3rem;
}

.app-vertical-screen .pick-photo__img-grid .pick-photo__img-pick.active {
  border-width: 0.2rem;
}

.app-vertical-screen .pick-photo__image-frame {
  margin-right: 0;
}

.app-vertical-screen .pick-photo__page-actions--vertical {
  position: relative;
  bottom: 0;
  margin: 10rem 0 3rem;
}

.app-vertical-screen .pick-photo__section {
  display: flex;
  flex-direction: column-reverse;
}

.app-vertical-screen .pick-photo__btn-rotate-actions button {
  width: 3.5rem;
  height: 3.5rem;
}
