/* Button */
.pb-button {
  width: 20.4rem;
  height: 7.5rem;
  border-radius: 2rem;
  border: 0.4rem solid var(--sync-button-border-color);
}

.pb-button.pb-button-no-border {
  border-color: transparent;
}

.pb-button .pb-button-text {
  color: var(--sync-button-text-color);
  font-weight: 700;
}

.pb-button-text::first-letter {
  text-transform: uppercase;
}

.pb-button-info {
  font-weight: 700;
  background: var(--sync-secondary-color);
}

.pb-button-primary {
  font-weight: 700;
  background: var(--sync-primary-color);
}

.pb-button-default {
  font-weight: 500;
  background: var(--sync-secondary-color);
}

.pb-button-transparent {
  background: transparent;
}

.pb-button.pb-button-transparent .pb-button-text {
  color: var(--sync-text-color);
}

.pb-button-disabled {
  opacity: 0.4;
}

.indi-spiner {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  width: 6rem;
  height: 6rem;
}

.indi-spiner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 4.8rem;
  height: 4.8rem;
  margin: 0.6rem;
  border: 0.6rem solid #fff;
  border-radius: 50%;
  -webkit-animation: anim-indi-spiner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  animation: anim-indi-spiner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.indi-spiner div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
  animation-delay: -0.45s;
}

.indi-spiner div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.indi-spiner div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

/* SquareButton */
.pb-square-button {
  width: 10rem;
  height: 10rem;
  border-radius: 2rem;
}

.pb-square-button-warning {
  border-color: transparent;
  background-color: var(--sync-input-background-color);
  background-clip: padding-box;
}

.pb-square-button.pb-square-button-warning .pb-square-button-text {
  color: var(--sync-primary-color);
}

@-webkit-keyframes anim-indi-spiner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes anim-indi-spiner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
