.shooting-position-page {
  width: 100%;
}

.shooting-position-page .page-title-margin {
  margin-top: 3rem;
  margin-bottom: 0rem;
}

.shooting-position-page img {
  pointer-events: none;
}

.shooting-position-page .webcam-frame {
  position: relative;
  width: 100%;
  height: 80.7rem;
  margin-top: 3.4rem;
  margin-bottom: 2.096rem;
  border-radius: 6.3rem;
  /* background-color: #010001; */
  background-color: transparent;
}

.shooting-position-page .webcam-frame-side {
  width: 100%;
}

.shooting-position-page .shooting-times-wrapper {
  width: 33rem;
  display: grid;
  grid-template-columns: auto auto;
  margin: auto;
  grid-gap: 1rem;
}

.shooting-position-page .shooting-times-item {
  width: 16rem;
  height: 13rem;
  border-radius: 3rem;
  background-color: white;
  border: 0.4rem solid var(--sync-border-title-2-color);
}

.shooting-position-page .shooting-times-item-title {
  font-size: 3.5rem;
  font-weight: 900;
}

.shooting-times-item-position {
  font-weight: 800;
  margin-top: 0.5rem;
  font-size: 2rem;
  letter-spacing: -1px;
}

.shooting-times-item-position.haveNotChosen {
  opacity: 0.7;
}

.shooting-position-page .shooting-times-item button {
  width: 100%;
  height: 100%;
  border-radius: 2.5rem;
  border: 0.6rem solid var(--sync-secondary-color);
  color: var(--sync-secondary-color);
}

.shooting-position-page .shooting-times-item button * {
  color: var(--sync-secondary-color);
}

.shooting-position-page .shooting-times-item.is-active button,
.shooting-position-page .shooting-times-item:active button {
  background-color: var(--sync-primary-color);
  border-color: white;
}

.shooting-position-page .shooting-times-item.is-active button *,
.shooting-position-page .shooting-times-item:active button * {
  color: white;
}

.shooting-position-page .shooting-times-wrapper.shooting-position-wrapper {
  grid-template-columns: auto;
}

.shooting-position-page .shooting-position-item {
  width: 27rem;
  margin: auto;
}

.shooting-position-page .shooting-position-item-title {
  font-size: 4rem;
}

.shooting-position-page .webcam-frame-side .carousel {
  margin: auto;
}

.shooting-position-page .webcam-frame-side .carousel,
.shooting-position-page .webcam-frame-side .carousel-main-view {
  width: 33rem;
  height: 55rem;
}

.shooting-position-page .webcam-frame img {
  max-width: 100%;
  max-height: 100%;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

.shooting-position-page .webcam-frame-wrapper {
  height: inherit;
  aspect-ratio: 1 / 1;
  position: relative;
  border-radius: 4rem;
}

.shooting-position-page .webcam-frame-wrapper > img {
  border-radius: 4rem;
  border: 0.5rem solid white;
  width: 100%;
}

.shooting-position-page .btn-action {
  top: 2.5rem;
  position: absolute;
  display: flex;
}

.shooting-position-page .btn-action > *:not(:last-of-type) {
  margin-right: 5.2rem;
}

.photo-slot-retake-layer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  border-radius: inherit;
}

.photo-slot-retake-layer svg path {
  stroke: var(--sync-primary-color);
}

.photo-slot.retaking-photo-thumb .photo-slot-retake-layer svg path {
  stroke: var(--sync-secondary-color);
}
