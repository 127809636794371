.printing-wait-for-printing__container {
  display: flex;
  flex-direction: column;
}

.printing-wait-for-printing__title-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  white-space: pre-line;

  &.is-has-mascot {
    margin-top: 16rem !important;
    margin-bottom: 8rem;
  }

  & > * {
    line-height: 10rem;
  }
}

.printing-wait-for-printing__image-wrapper {
  width: 153rem;
  height: 65.1rem;

  img {
    max-width: 100%;
    margin: auto;
    width: 100%;
    height: 100%;
  }
}

/* Vertical Screen */
.app-vertical-screen {
  .printing-wait-for-printing__title-container {
    justify-content: center;
    align-items: center;
  }

  .printing-wait-for-printing__image-wrapper {
    height: unset;
    max-width: 95%;
  }

  .printing-wait-for-printing__title-wrapper {
    max-width: 80%;
  }

  .printing-wait-for-printing__title-wrapper.is-has-mascot {
    margin-bottom: 16rem;
    margin-top: 0rem !important;
  }

  .printing-wait-for-printing__image-wrapper {
    height: 70rem;
    width: unset;
  }
}
