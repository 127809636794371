.app-timer-text {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  color: var(--sync-button-text-color);
  background-color: var(--sync-primary-color);
  position: fixed;
  top: 2rem;
  right: 2rem;
  text-align: center;
  line-height: 6.7rem;
}

.app-vertical-screen .app-timer-text {
  width: 7.5rem;
  height: 7.5rem;
  padding: 0.5rem;
}
