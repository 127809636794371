.pb-slider-wrapper {
  width: 52rem;

  .pb-slider {
    width: inherit;
  }

  .carousel-indicators {
    button {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      border: 1px solid transparent;
      position: relative;
    }

    button:not(:last-child) {
      margin-right: 1.82rem;
    }

    button.active svg circle:first-child {
      fill: var(--sync-primary-color);
    }

    button.active svg circle:last-child {
      stroke: var(--sync-text-color);
    }
  }
}
