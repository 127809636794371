.pb-loader {
  position: relative;
  background-color: transparent;
}

.pb-loader .pb-loader-icon-wrapper {
  position: absolute;
  width: 22.5rem;
  height: 9rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-loading);
  background-image: var(--background-image-url);
  background-repeat: no-repeat;
  background-size: cover;
}

.pb-loader .pb-loader-icon-wrapper .pb-loader-img {
  width: 45rem;
}
