.qr-code .qr-code__wrapper {
  width: 120.889rem;
  height: 68rem;
  max-width: 100%;
}

.qr-code__momo-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0.5rem solid var(--momo-color);
  border-radius: 3rem;
  background-color: white;
  padding: 3rem;
  padding-bottom: 2rem;
}

.qr-code__momo-payment-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.qr-code__momo-payment-money-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code__momo-payment-money-info > * {
  margin-left: 1rem;
}

.qr-code__momo-payment-money-info .divide-vertical-line {
  border-left: 0.1rem solid var(--divide-border-color);
  width: 1px;
  height: 1.5rem;
}

.qr-code__momo-payment-info img {
  width: 9rem;
  height: 9rem;
}

.qr-code__momo-payment-info .pb-typography {
  color: var(--momo-color);
}

.qr-code__qr-refresh-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

.qr-code__qr-refresh-wrapper .pb-typography {
  color: var(--momo-color);
}

.qr-code__qr-refresh-wrapper .qr-code__btn-qr-refresh {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.qr-code__qr-refresh-wrapper .qr-code__btn-qr-refresh svg {
  height: 4rem;
  width: 4rem;
  margin-left: 1rem;
}

.qr-code__qr-refresh-wrapper .qr-code__btn-qr-refresh svg path {
  stroke: var(--momo-color);
}

.qr-code__qr-refresh-wrapper.qr-code__qr-refresh-wrapper--disabled {
  opacity: 0.4;
}

.qr-code__qr-refresh-wrapper.qr-code__qr-refresh-wrapper--loading
  .qr-code__btn-qr-refresh
  svg {
  animation: spinner 1s linear infinite;
}

/* App Vertical Screen */
.app-vertical-screen .qr-code .qr-code__wrapper {
  height: 100%;
}

.app-vertical-screen .qr-code__page-actions--vertical {
  position: relative;
  bottom: 0;
  margin-top: 10rem;
}

.app-vertical-screen .qr-code {
  height: 100%;
  display: flex;
  min-height: 0;
  flex-direction: column;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
