.pb-select {
  .pb-select__input,
  .pb-select__item {
    position: relative;
    cursor: pointer;
    background-color: var(--sync-secondary-color);
    color: var(--sync-text-color);
    padding: 1.35rem 2rem;
    font-weight: 900;
    display: flex;
    align-items: center;

    .pb-typography {
      font-size: 1.8rem;
    }
  }

  .pb-select__input {
    border-radius: 1.5rem;

    .pb-select__prefix-icon,
    .pb-select__surfix-icon {
      margin-right: 1rem;

      & > * {
        width: 3rem;
        height: 3rem;
      }
    }

    .pb-select__surfix-icon {
      margin-left: auto;
      margin-right: 0;
      transition: all 0.3s;
    }
  }

  .pb-select__list {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    list-style: none;
    z-index: var(--z-index-dropdown);

    .pb-select__item {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      border-top: 1px solid var(--sync-button-border-color);
      cursor: pointer;
      padding: 1.8rem 2rem;

      &:last-child {
        border-bottom-left-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
      }
    }
  }

  &.pb-select-show {
    .pb-select__input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .pb-select__list {
      display: block;
    }

    .pb-select__surfix-icon {
      transform: scale(-1, -1);
    }
  }
}
