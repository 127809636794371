.pb-typography {
}

.pb-typography-firstCapCase::first-letter {
  text-transform: uppercase;
}

.pb-typography-primary-color {
  color: var(--sync-primary-color);
}

.pb-typography-secondary-color {
  color: var(--sync-secondary-color);
}

.pb-typography-warning-color {
  /* color: var(--sync-warning-color); */
}
