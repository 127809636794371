.add-filter__title {
  margin-top: 7.6rem;
  margin-bottom: 6.1rem;
}

.add-filter__section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6.5rem;
}

.add-filter__photo-sheet--wrapper {
  display: flex;
  align-items: center;
  margin-right: 6rem;
  height: 64rem;
  width: 64rem;
}

.add-filter__photo-sheet--wrapper .add-filter__photo-sheet {
  max-height: 64rem;
  max-width: 64rem;
  margin: auto;
}

.add-filter__grid--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72.8rem;
}

.add-filter__grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  row-gap: 2.265rem;
}

.add-filter__grid .filter-item {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 28.4rem;
  height: 30.8rem;
  border-radius: 2rem;
  padding: 0.998rem 0.998rem 0.6rem 0.998rem;
}

.add-filter__grid .filter-item img {
  width: 25.3rem;
  height: 25.3rem;
}

.add-filter__grid .filter-item.active {
  background-color: #fef8ee;
}

.add-filter__grid .filter-item:not(:last-of-type) {
  margin-right: 0.644rem;
}

.add-filter__grid .filter-name {
  font-weight: 800;
  font-size: 2rem;
  line-height: 3.4rem;
  color: var(--sync-text-color);
}

.add-filter__grid .filter-item.active .filter-name {
  color: var(--sync-primary-color);
}

.add-filter__grid .filter-thumbnail {
  border-radius: inherit;
}

.add-filter__grid .thumbnail-filter-svg {
  display: block;
  width: 25.3rem;
  height: 25.3rem;
  overflow: hidden;
  position: absolute;
  border-radius: 2rem;
  border: 0.8rem solid var(--sync-button-border-color);
}

.add-filter__grid .filter-item.active .thumbnail-filter-svg {
  border: 0.2rem solid #fef8ee;
}

/* Vertical Screen */
.app-vertical-screen .add-filter {
}

.app-vertical-screen .add-filter__page-actions--vertical {
  position: relative;
  bottom: 0;
  margin: 10rem 0 5rem;
}

.app-vertical-screen .add-filter__photo-sheet--wrapper {
  margin: 0;
}

.app-vertical-screen .add-filter__section {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 0;
  padding-top: 5.5rem;
}

.app-vertical-screen .add-filter__grid {
  grid-template-columns: repeat(4, auto);
  column-gap: 2rem;
  margin-bottom: 3rem;
}

.app-vertical-screen .add-filter__grid .filter-item {
  width: 18rem;
  height: 20rem;
}

.app-vertical-screen .add-filter__grid .filter-item img,
.app-vertical-screen .add-filter__grid .filter-item svg {
  width: 15rem;
  height: 15rem;
}

.app-vertical-screen .add-filter__grid .thumbnail-filter-svg {
  border: 0.4rem solid white;
}
