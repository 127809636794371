.title-and-logo {
  width: 100%;
  display: none;
  text-align: center;
  margin-bottom: 6rem;
  flex-direction: column;
  align-items: center;
}

.title-and-logo .pb-typography {
  font-size: 9rem !important;
  margin-bottom: 6rem;
  line-height: 9rem;
}

.title-and-logo .title-and-logo__start-mascot-img {
  height: 35rem;
  max-width: 82.4rem;
  margin-bottom: 2rem;
}

.title-and-logo .title-and-logo__logo {
  height: 11rem;
}

/* App Vertical Screen */
.app-vertical-screen .title-and-logo {
  display: flex !important;
}
