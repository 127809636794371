.edit-photo__title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6rem;
}

/* Vertical Screen */
.app-vertical-screen .edit-photo {
}

.app-vertical-screen .edit-photo__page-actions--vertical {
  position: relative;
  bottom: 0;
  margin: 10rem 0 5rem;
}

.app-vertical-screen .edit-photo__title {
  gap: 5rem;
}

.app-vertical-screen .edit-photo__title .pb-typography {
  font-size: 4rem !important;
}
