.pb-photo-sheet {
  position: relative;
}

.pb-photo-sheet .pb-photo-sheet-picture-item {
  position: absolute;
}

.pb-photo-sheet .pb-photo-sheet-qr-code {
  position: absolute;
}

.pb-photo-sheet-theme-image {
  max-height: inherit;
  max-width: inherit;
  position: relative;
  z-index: 1;
  pointer-events: none;
}

/* Photo Filter */
.pb-photo-filter {
  position: relative;
}

.pb-photo-filter .pb-photo-filter-image {
  display: block;
  visibility: hidden;
  width: 100%;
  height: 100%;
}

.pb-photo-filter .pb-photo-filter-svg {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
}

.pb-photo-filter .pick-photo__img-order-no {
  position: absolute;
  width: 2.2rem;
  height: 2.2rem;
  top: 1rem;
  left: 0.8rem;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2rem solid var(--sync-primary-color);
}

.pb-photo-filter .pick-photo__img-order-no-text {
  color: var(--sync-primary-color);
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.target {
  position: absolute;
}

.moveable-rotation-control {
  width: 26px !important;
  height: 26px !important;
  transform: translate(-2.5px) scale(1) !important;
  background-image: url('assets/icon/rotate-icon.svg') !important;
  background-repeat: no-repeat !important;
  background-size: 23px !important;
}

.moveable-resizable {
  width: 18px !important;
  height: 18px !important;
  margin-left: -9px !important;
}
