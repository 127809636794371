.printing-photo-upload .printing-photo-upload__wrapper {
  width: 120.889rem;
  height: 68rem;
}

.printing-photo-upload__qr-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 0.5rem solid var(--momo-color); */
  border-radius: 4rem;
  background-color: white;
  padding: 5.5rem;
  padding-bottom: 2rem;
}

.printing-photo-upload__qr-link-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.5rem;
}

.printing-photo-upload__qr-link-wrapper .pb-typography {
  color: black;
  font-size: 3.5rem;
  font-weight: bold;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
