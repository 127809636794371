/* Popup style */
.pb-popup {
  position: fixed;
  background: #ffffff50;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-popup);
}

.pb-popup-box {
  position: relative;
  width: 50%;
  min-width: 80rem;
  height: auto;
  max-height: 70vh;
  background-color: #fff;
  border-radius: 4px;
  padding: 4rem 10rem 2rem 10rem;
  border: 1px solid #999;
  text-align: center;
  border-radius: 2rem;
  font-weight: 700;
  background-image: url('assets/v2/images/blue_cartoon_background_1.png');
  background-size: 40rem;
}

.pb-popup-actions {
  margin-top: 3rem;
}

.pb-popup-actions > *:not(:last-child) {
  margin-right: 3rem;
}

.pb-popup-close-icon {
  position: absolute;
  top: -2rem;
  right: -2rem;
  cursor: pointer;
  background: white;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  line-height: 4rem;
  text-align: center;
  border: 0.3rem solid var(--sync-primary-color);
  font-size: 4rem;
  color: var(--sync-primary-color);
  font-weight: 600;
}

.pb-popup__pop-btn-next.pb-popup__popup-btn-next--with-timer {
  width: fit-content;
  padding-left: 1rem;
  padding-right: 1rem;
}
