.app-content .app-content-pb-loader-icon-wrapper {
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: var(--z-index-loading);
}

/* App vertical screen */
.app-vertical-screen {
  flex-direction: column;
}

.app-vertical-screen > div:not(.title-and-logo):not(.page-actions) {
  height: 100%;
  min-height: 0;
}

.app-vertical-screen .page-action-vertical {
  display: block;
  position: relative;
  bottom: 0;
  text-align: center;
  height: fit-content;
  margin: 10rem 0 20rem;
}

.mascot-loading-img {
  height: 35%;
  max-width: 100%;
  margin-top: 25rem;
}
