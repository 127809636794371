.carousel {
  width: 52rem;
  height: 52rem;
  position: relative;
}

.carousel-main-view {
  width: inherit;
  height: inherit;
  overflow: hidden;
}

.carousel-content {
  position: relative;
  display: flex;
}

.carousel-item {
  width: inherit;
  height: inherit;
}

.carousel-item:not(:last-child) {
  margin-right: 2rem;
}

.carousel-indicators {
  display: flex;
  width: fit-content;
  margin-top: 2.5rem;
  justify-content: center;
  width: 100%;
}

.carousel-indicators button {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1px solid transparent;
  position: relative;
}

.carousel-indicators button:not(:last-child) {
  margin-right: 1.82rem;
}

.carousel-indicators button.active svg circle:first-child {
  fill: var(--sync-primary-color);
}

.carousel-indicators button.active svg circle:last-child {
  stroke: var(--sync-text-color);
}

.carousel .carousel__btn-left,
.carousel .carousel__btn-right {
  position: absolute;
  width: 6rem !important;
}

.carousel .carousel__btn-left > *,
.carousel .carousel__btn-right > * {
  line-height: unset !important;
}

.carousel .carousel__btn-left {
  left: -9rem;
}

.carousel .carousel__btn-right {
  right: -9rem;
}
