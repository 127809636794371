.btn-offline {
  width: 6.5rem;
  height: 6.5rem;
  position: absolute;
  top: 9rem;
  right: 2rem;
}

.btn-offline.btn-offline-in-start-page {
  top: 2rem;
  right: 2rem;
}

.btn-offline svg {
  width: inherit;
  height: inherit;
}

.btn-offline svg path {
  fill: var(--danger-color);
}
